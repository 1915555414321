import React, { Component } from 'react';
import { FormGroup, H5, InputGroup, Elevation, Card } from "@blueprintjs/core";
import StateDropDown from "../UI/StateDropDown";

export class Address extends Component {

    state = {
        addressState: "Select State"
    }

    createName = (field) => this.props.prefix.concat(field);

    addressFieldName = () => this.createName("address");
    address2FieldName = () => this.createName("address2");
    cityFieldName = () => this.createName("city");
    stateFieldName = () => this.createName("state");
    stateSelectFieldName = () => this.createName("stateselect");
    zipFieldName = () => this.createName("zip");


    addressValue = () => this.props.values[this.addressFieldName()];
    address2Value = () => this.props.values[this.address2FieldName()];
    cityValue = () => this.props.values[this.cityFieldName()];
    stateValue = () => this.props.values[this.stateFieldName()];
    zipValue = () => this.props.values[this.zipFieldName()];

    companySpace = () => {
        if (this.props.showCompanyName)
            return (<></>);

        return (<div style={{ height: "62px" }}></div>);
    }

    companyName = () => {
        if (this.props.showCompanyName) {
            return (
                <FormGroup
                    {...this.props.GroupProps("companyName")}
                    label="Company Name">
                    <InputGroup
                        small
                        maxLength="100"
                        disabled={this.props.disabled}
                        {...this.props.GroupProps("companyName")}
                        name={"companyName"}
                        placeholder="Company Name"
                        value={this.props.values.companyName}
                        onChange={this.props.handleInputChange} />
                </FormGroup>);
        }

    }
    render() {

        return (
            <>
                <Card elevation={Elevation.TWO}>
                    <H5 className="cardHeadline" style={{ marginBottom: "0px", background: this.props.bgc , color: "white", height: "25px", paddingLeft: "5px", paddingTop: "2px" }}>{this.props.title}</H5>
                    {this.props.grad && <div style={{
                        height: "2px", marginBottom: "5px", borderRadius: "2px", background: "linear-gradient(90deg, rgba(4,96,7,1) 45%, rgba(248,136,0,1) 73%)"
                    }} />}
                    <form>
                        {this.companyName()}

                        <FormGroup
                            label="Address"
                            {...this.props.GroupProps(this.addressFieldName())}>
                            <InputGroup
                                small
                                maxLength="50"
                                disabled={this.props.disabled}
                                {...this.props.GroupProps(this.addressFieldName())}
                                name={this.addressFieldName()}
                                placeholder="Address"
                                value={this.addressValue()}
                                onChange={this.props.handleInputChange} />
                        </FormGroup>
                        <FormGroup
                            label="Address 2"
                            {...this.props.GroupProps(this.address2FieldName())}>
                            <InputGroup
                                small
                                maxLength="50"
                                disabled={this.props.disabled}
                                {...this.props.GroupProps(this.address2FieldName())}
                                name={this.address2FieldName()}
                                placeholder="Address2"
                                value={this.address2Value()}
                                onChange={this.props.handleInputChange} />
                        </FormGroup>
                        <FormGroup
                            label="City"
                            {...this.props.GroupProps(this.cityFieldName())}>
                            <InputGroup
                                small
                                maxLength="50"
                                disabled={this.props.disabled}
                                {...this.props.GroupProps(this.cityFieldName())}
                                name={this.cityFieldName()}
                                placeholder="City"
                                value={this.cityValue()}
                                onChange={this.props.handleInputChange} />
                        </FormGroup>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ width: "50%", paddingRight: "10px" }}>
                                <div style={{ marginRight: "5px" }}>
                                    <StateDropDown
                                        labelText={"State "}
                                        labelInfo={<span className="bp3-text-muted">{this.props.GroupProps(this.stateFieldName())}</span>}
                                        selectedState={this.stateValue()}
                                        selectProps={
                                            {
                                                disabled: this.props.disabled,
                                                style: {
                                                    ...this.props.GroupProps(this.stateSelectFieldName()),
                                                    height: "24px"
                                                },
                                                small: true,
                                                onChange: (e) => {
                                                    this.props.handleInputChange({
                                                        target: { name: this.stateFieldName(), value: e.target.value }
                                                    });
                                                }
                                            }} />
                                </div>
                            </div>
                            <div style={{ width: "50%", display: "inline-block" }}>
                                <FormGroup
                                    label="Zip"
                                    {...this.props.GroupProps(this.zipFieldName())}>
                                    <InputGroup
                                        small
                                        maxLength="25"
                                        disabled={this.props.disabled}
                                        {...this.props.GroupProps(this.zipFieldName())}
                                        name={this.zipFieldName()}
                                        placeholder="Zip"
                                        value={this.zipValue()}
                                        onChange={this.props.handleInputChange} />
                                </FormGroup>
                            </div>
                            {this.props.extraSpace ? <div style={{ height: "62px" }} /> : null}
                        </div>
                        {this.companySpace()}
                    </form>
                </Card>
            </>
        );
    }
}