import React, { useState, useEffect } from 'react';
import { Spinner, ProgressBar, Classes, Popover, Callout, Tabs, Tab, TextArea, HTMLSelect, Button, FormGroup, RadioGroup, Label, Radio, H2, Checkbox, Intent, Card, Elevation, H5, InputGroup } from "@blueprintjs/core";
import { Address } from "./Address";
import { IconNames } from "@blueprintjs/icons";
import { CreditCardPaymentInfo } from "./CreditCardPaymentInfo";
import titleImg from '../images/logo1.png';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import LabelStateSelect from "../UI/StateDropDown";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import useWindowDimensions from "../Hooks/UseWindowDimensions";
import './Escapees.css';


pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

export default function EscapeesJoin(props) {
    const [showProcessing, setShowProcessing] = useState(0);
    const [popupType, setPopupType] = useState('req');
    const [clickPass, setClickPass] = useState(false);
    const [shouldValidate, setValidate] = useState(false);
    const [pdfDetails, setPdfDetails] = useState({ numPages: 0, id: 0 });
    const favicon = document.getElementById("favicon");
    const cardFrom = new URLSearchParams(window.location.search).get("crdfrm");
    const partnerCustomerCode = new URLSearchParams(window.location.search).get("pcc");
    const partnerExpiration = new URLSearchParams(window.location.search).get("pce");
    const isMds = !partnerCustomerCode && !partnerExpiration;

    favicon.href = "https://cdn.mdshosted.com/web/siteicon.png";

    function onDocumentLoadSuccess(numPages, id) {
        setPdfDetails({ numPages, id: Number(id) });
    }
    document.title = "Program Application";
    const [addressValidated, setAddressValidated] = useState({ mailing: false, billing: false, company: false, temporary: false });
    const [addressToValidate, setAddressToValidate] = useState();

    const addressFields = [
        'companyaddress', 'companyaddress2', 'companycity', 'companystate',
        'companyzip',
        'temporaryaddress',
        'temporaryaddress2',
        'temporarycity',
        'temporarystate',
        'temporaryzip',
        'mailingaddress',
        'mailingaddress2',
        'mailingcity',
        'mailingstate',
        'mailingzip',
        'billingaddress',
        'billingaddress2',
        'billingcity',
        'billingstate',
        'billingzip'];

    const notRequired = ["issuer", "billingaddress2", "mailingaddress2", "sameAs",
        "companyName",
        "companyaddress",
        "companyaddress2",
        "companycity",
        "companystate",
        "companyzip",
        "homePhone",
        "temporaryaddress",
        "gastanksize",
        "temporaryaddress2",
        "backupEmail",
        "temporarycity",
        "temporarystate",
        "suffix",
        "middleinitial",
        "shippingMessage",
        "temporaryzip",
        "issuer"];
    const paymentNotReq = {
        credit: ["achRoutingNumber", "achAccountNumber", "achVerfyAccountNumber"],
        ach: ["name", "cvc", "expiry", "number"]
    };
    const billingFields = ["billingaddress", "billingcity", "billingstate", "billingzip"];
    const noNumberFields = ["lpstate", "dlissue", "billingstate", "mailingstate", "companystate", "temporarystate"];
    const numberOnlyFields = ["achRoutingNumber", "achVerfyAccountNumber", "achAccountNumber"];
    const noNegNumberFields = ["numAddReqCards", "tankSize", "gasTankSize"];
    const { height, width } = useWindowDimensions();
    const [popupOpen, setPopupOpen] = useState(false);
    const [imageReady, setImageReady] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [pdfs, setPdfs] = useState();
    const [shippingOptions, setShippingOptions] = useState();

    function showPdfs() {
        const mobileStyle = { marginTop: "10px" }
        const style = { marginLeft: "15vw", width: "70vw", marginTop: "5vh" };

        if (!pdfs)
            return (<>loading pdf data</>);

        if (!pdfs.length === 0 || pdfs.filter((f) => !f.validated).length === 0) {
            const vers = pdfs.map(m => `${m.pdfTitle}=${m.pdfVersion}`).join(',');
            post(vers);
            return processing();
        }

        const allPdfs = [...pdfs];
        allPdfs.sort((a, b) => a.pdfOrder > b.pdfOrder ? 1 : -1);

        const currentPdf = pdfDetails.id === 0 ?
            allPdfs.find((f) => !f.validated)
            : pdfs.find((f) => Number(f.pdfId) === Number(pdfDetails.id));

        return (
            <div style={isMobile ? mobileStyle : style}>
                <Card elevation={Elevation.TWO} >
                    <div id="pdfWrapper" style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}>
                        <Document
                            file={{ url: currentPdf.pdfUrl }}
                            loading={(<Spinner size={20} />)}
                            onLoadSuccess={(e) => {
                                if (pdfDetails.id === 0)
                                    onDocumentLoadSuccess(e.numPages, currentPdf.pdfId)
                            }}>
                            {
                                Array.from(
                                    new Array(pdfDetails.numPages),
                                    (el, index) => (
                                        <Page
                                            loading=""
                                            width={width * .70}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1} />
                                    ),
                                )
                            }
                        </Document>
                    </div>
                </Card>
                <div style={{ marginTop: "25px" }}>
                    <Button
                        large
                        style={{ float: "right" }}
                        disabled={pdfDetails.numPages === 0}
                        onClick={() => {
                            const allpdfs = [...pdfs];
                            allpdfs.find((f) => f.pdfId === currentPdf.pdfId).validated = true;
                            setPdfs(allpdfs);
                            setPdfDetails({ numPages: 0, id: 0 });
                        }}
                        icon="endorsed" intent="success" text="Agree" />
                    <Button
                        large
                        style={{ float: "right", marginRight: "25px" }}
                        text="Cancel"
                        disabled={pdfDetails.numPages === 0}
                        icon="ban-circle"
                        onClick={() => {
                            setPdfDetails({ numPages: 0, id: 0 });
                            setShowProcessing(0);
                        }} />
                </div>
            </div>
        );
    }

    function checkIsValid() {

        for (const property in state) {
            if (!notRequired.includes(property) && !paymentNotReq[state["paymentMethod"]].includes(property)) {
                if (!(state["sameAs"] && billingFields.includes(property))) {
                    if (state[property] === '') {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    function hasACH() {
        if (state.achAccountNumber
            || state.achRoutingNumber
            || state.achVerfyAccountNumber)
            return true;

        return false;
    }
    function hasCredit() {

        if (state.number
            || state.expiry
            || state.cvc
            || state.name) {

            return true;

        }

        return false;
    }
    const [state, setState] = useState(
        {
            suffix: '',
            middleinitial: '',
            paymentMethod: 'ach',
            achAccountTypeRadio: 'checking',
            shippingOption: '1',
            shippingMessage: '',
            achAccountNumber: '',
            achRoutingNumber: '',
            achVerfyAccountNumber: '',
            sameAs: false,
            firstName: '',
            lastName: '',
            companyName: '',
            companyaddress: '',
            companyaddress2: '',
            companycity: '',
            companystate: '',
            companyzip: '',
            temporaryaddress: '',
            temporaryaddress2: '',
            temporarycity: '',
            temporarystate: '',
            temporaryzip: '',
            mailingaddress: '',
            mailingaddress2: '',
            mailingcity: '',
            mailingstate: '',
            mailingzip: '',
            billingaddress: '',
            billingaddress2: '',
            billingcity: '',
            billingstate: '',
            billingzip: '',
            referrer: 'Escap',
            cvc: '',
            expiry: '',
            name: '',
            number: '',
            mobilePhone: '',
            homePhone: '',
            email: '',
            backupEmail: '',
            numAddReqCards: '',
            dlnum: '',
            lpnum: '',
            dlissue: '',
            tankSize: '',
            lpstate: '',
            issuer: '',
            gastanksize: '',
            cardIsValid: false,
            cardFrom: cardFrom,
            partnerCustomerCode: isMds ? 'PENDING' : partnerCustomerCode,
            partnerExpiration: partnerExpiration,
            isMds: isMds,
            pdfVersions: '1,1',
            custType: '1'
        });

    useEffect(() => {
        console.log(window.location.href);

        console.log("version 0.18 escapees");

        function onLoad() {

            const headers = {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                'Access-Control-Allow-Origin': '*',
            };

            setTimeout(() => {
                axios.get('api/customerportal/getEscapeesPdfDetails',
                    {
                        headers: headers
                    })
                    .then((response) => {
                        setPdfs(response.data.joinPdfDetails.map((el, i) => { return { ...el, validated: false } }));
                        setShippingOptions(response.data.shippingOptions)
                    })
                    .catch((e) => handleJoinError(e));
            }, 1000);
        }

        onLoad();

        const img = new Image();
        img.onload = () => {
            setImageReady(true);
        }
        img.src = titleImg;

    }, [])


    function updateState(name, value) {
        const newState = { ...state };
        newState[name] = value;
        setState(newState);
    }


    function handleInputChange(e) {
        const { name, value } = e.target;

        const re = /^[0-9\b]+$/;
        const al = /^[A-Za-z]+$/;

        if (value !== '' && numberOnlyFields.includes(name) && !re.test(value))
            return;

        if (value !== '' && noNumberFields.includes(name) && !al.test(value))
            return;

        if (value !== '' && noNegNumberFields.includes(name) && !re.test(value))
            return;
        if (value !== '' && noNegNumberFields.includes(name) && value === "-")
            return;

        if (value !== '' && noNegNumberFields.includes(name) && isNaN(parseInt(value)))
            return;
        if (value !== '' && noNegNumberFields.includes(name) && parseInt(value) < 0)
            return

        if (!isValid)
            setIsValid(true);

        updateState(name, value);
    }

    function handleCardValidAndIssuer(issuer, isValid) {
        const newState = { ...state };
        newState.issuer = issuer;
        newState.cardIsValid = isValid;
        setState(newState);
    }

    function handleCardInput(name, value) {
        updateState(name, value);
    }

    function handleShippingOptionsRadioChange(event) {
        const value = event.target.value;
        updateState("shippingOption", value);
    }

    function handleAccountTypeRadioChange(event) {
        const value = event.target.value;
        updateState("achAccountTypeRadio", value);
    }

    function handleSameAs(event) {
        const check = event.target.checked;
        updateState("sameAs", check);
    }

    function handleUseCredit() {
        setClickPass(true);
    }

    const ccWarningMobileInfoStyle = {
        Height: "270px",
        overfllow: "scroll"
    }

    function creditPaymentMethod() {
        if (!clickPass) {
            const useCreditSytle =
            {
                marginRight: isMobile ? "0px" : "15px",
                marginTop: "15px",
                float: isMobile ? "right" : ""
            };
            function headline() {
                const headlineText = "ACH transactions have the lowest costs associated with any payment system and are widely considered to be the safest financial transactions.";
                if (isMobile)
                    return (<span style={{ fontSize: ".99em" }}><b>{headlineText}</b></span>);
                else
                    return (<H5>{headlineText}</H5>);
            }
            return (
                <div style={isMobile ? ccWarningMobileInfoStyle : {}}>
                    <Callout style={{ height: isMobile ? "260px" : "165px", overflowX: "hidden", overflowY: "auto" }}
                        intent={Intent.DANGER}
                        icon={IconNames.ERROR}
                        title={"Please note: It is recommended that your primary payment method be ACH Bank Draft and your default payment (second) method be credit card."}>
                        {headline}

                        There are no additional fees to use ACH. You will need your bank account and routing number to set up this payment type.  Using ACH payments, you will enjoy up to <b> 3%</b> in savings compared to credit cards.
                        A credit card can also be used, but please note that if you select a credit card to be your primary payment method, you will not receive the cash price from the retailer prior to receiving the Fuel Access Network discount. In addition, your credit card may charge a processing fee. The cost of the processing fee is established by each individual credit card company.



                    </Callout>
                    <div style={{ float: "right", marginTop: "20px" }}>
                        <Button
                            large
                            style={{ float: "right" }}
                            onClick={() => handleTabChange('ach')}
                            icon="endorsed" intent="success" text="Continue with ACH" />
                        <Button
                            small
                            style={useCreditSytle}
                            intent="danger"
                            text="Use Credit"
                            icon="ban-circle"
                            onClick={handleUseCredit} />
                    </div>
                </div>);
        }


        return (
            <div>
                <CreditCardPaymentInfo
                    GroupProps={GroupProps}
                    number={state.number}
                    expiry={state.expiry}
                    cvc={state.cvc}
                    name={state.name}
                    handleCardValidAndIssuer={handleCardValidAndIssuer}
                    handleInputChange={handleCardInput} />
            </div >);
    }

    function achPaymentMethod() {
        return (

            <div style={{ maxWidth: "400px" }}>
                <RadioGroup
                    name="accountType"
                    onChange={handleAccountTypeRadioChange}
                    selectedValue={state.achAccountTypeRadio}
                    inline>
                    <Radio large label="Checking" value="checking" />
                    <Radio large label="Savings" value="savings" />
                </RadioGroup>
                <FormGroup
                    label="Routing Number*" {...GroupProps("achRoutingNumber")}>
                    <InputGroup
                        small
                        maxlength="9"
                        {...GroupProps("achRoutingNumber")}
                        onChange={handleInputChange}
                        value={state.achRoutingNumber}
                        name="achRoutingNumber"
                        id="routing-input"
                        placeholder="Routing Number" />
                </FormGroup>
                <FormGroup
                    label="Account Number*"
                    {...GroupProps("achAccountNumber")}>

                    <InputGroup
                        small
                        maxLength="35"
                        {...GroupProps("achAccountNumber")}
                        onChange={handleInputChange}
                        value={state.achAccountNumber}
                        name="achAccountNumber"
                        id="account-number-input"
                        placeholder="Account Number" />
                </FormGroup>

                <FormGroup
                    label="Verify Account Number*"
                    {...GroupProps("achVerfyAccountNumber")}>
                    <InputGroup
                        small
                        maxLength="35"
                        {...GroupProps("achVerfyAccountNumber")}
                        onChange={handleInputChange}
                        value={state.achVerfyAccountNumber}
                        name="achVerfyAccountNumber"
                        id="verify-account-number-input"
                        placeholder="Verify Account Number" />
                </FormGroup>
            </div>
        );
    }

    function HandleSubmit() {

        setValidate(true);

        if (checkIsValid()) {

            if (hasCredit() && !isValidExpiry()) {
                setPopupType("exp");
                setPopupOpen(true);
                return;
            }
            if (state.achRoutingNumber.length !== 9 && hasACH()) {
                setPopupType("ach");
                setPopupOpen(true);
                return;
            }

            if ((!isValidEmail(state.email) || ((state.backupEmail !== '' && !isValidEmail(state.backupEmail))))) {
                setPopupType("bem");
                setPopupOpen(true);
                return;
            }

            if (!phoneValueIsValid(state.mobilePhone)) {
                setPopupType("mph");
                setPopupOpen(true);
                return;
            }
            for (const property in noNegNumberFields) {
                if (parseInt(state[property]) < 0) {
                    setPopupType("nnn");
                    setPopupOpen(true);
                    return;
                }
            }

            if (hasCredit()) {
                if (state.issuer !== "mastercard" && state.issuer !== "visa" && state.issuer !== "discover") {
                    setPopupType("ct");
                    setPopupOpen(true);
                    return;
                }

                if (!state.cardIsValid) {
                    setPopupType("valid");
                    setPopupOpen(true);
                    return;
                }
            }

            
            for (var i = 0; i < addressFields.length; i++) {
                const field = addressFields[i];
                console.log(field);
                console.log(state[field]);
                if (state[field] && state[field].length > 50)
                {
                    console.log(state[field]);
                    setPopupType("len");
                    setPopupOpen(true);
                    return;
                }
            }

            if (
                (state.middleinitial && state.middleinitial.length > 1)
                || (state.email && state.email.length > 255)
                || (state.backupEmail && state.backupEmail.length > 255)
                || (state.achRoutingNumber && state.achRoutingNumber.length > 9)
                || (state.shippingMessage && state.shippingMessage.length > 500)
                || (state.suffix && state.suffix.length > 5)
                || (state.homePhone && state.homePhone.length > 20)
                || (state.mobilePhone && state.mobilePhone.length > 20)
                || (state.lastName && state.lastName.length > 50)
                || (state.firstName && state.firstName.length > 50)
                || (state.lpnum && state.lpnum.length > 25)
                || (state.achAccountNumber && state.achAccountNumber.length > 35)
                || (state.achRoutingNumber && state.achRoutingNumber.length > 9)
                || (state.dlnum && state.dlnum.length > 50)) {
                console.log("here");
                setPopupType("len");
                setPopupOpen(true);
                return;
            }


            setShowProcessing(4);

        }
        else {
            setPopupType("req");
            setPopupOpen(true);
        }
    }

    function phoneValueIsValid(str) {

        if (!str || str.length === 0)
            return false;

        var pe = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;

        if (!pe.test(str))
            return false;

        let jn = str.replace(/\)/g, "");
        jn = jn.replace(/\(/g, "");
        jn = jn.replace(/-/g, "");

        if (jn.length > 10)
            return false;

        let start = 0;
        const end = jn.length;
        if (jn.length >= 4)
            start = jn.length - 4;
        else
            start = 0;

        var l4 = jn.substring(start, end);

        const re = /^[0-9\b]+$/;

        if (!l4 || l4.length < 1 || !re.test(l4))
            return false;

        return true;
    }

    function getPopupContent() {
        if (popupType === 'ach') {
            return (
                <div>
                    <H5>ACH Routing Number</H5>
                    <p>The ACH Routing field must be 9 digits.</p>
                </div>);
        }
        if (popupType === 'req') {
            return (
                <div>
                    <H5>Required Fields Missing</H5>
                    <p>Please complete all the required fields and try again.</p>
                </div>);
        }
        if (popupType === 'ct') {
            return (
                <div>
                    <H5>Credit card type not accepted</H5>
                    <p>Only Master Card, Visa and Discover is accepted.</p>
                </div>);
        }
        if (popupType === 'nnn') {
            return (
                <div>
                    <H5>Must be a positive Number</H5>
                    <p>The Number of Requested cards, diesel tanks size</p>
                    <p>and gas tank size must have positive numbers</p>
                </div>);
        }
        if (popupType === 'exp') {
            return (
                <div>
                    <H5>Credit Card Expiry</H5>
                    <p>The date entered for the credit card is and expired date.</p>
                    <p>Please correct to continue.</p>
                </div>);
        }
        if (popupType === 'len') {
            return (
                <div>
                    <H5>Invalid Field Length</H5>
                    <p>There are fields that are not a valid length</p>
                </div>);
        }
        if (popupType === 'bem') {
            return (
                <div>
                    <H5>Email not valid</H5>
                    <p>The Email address supplied is not in a valid format.</p>
                    <p>use format: someone@someplace.com</p>
                </div>);
        }
        if (popupType === 'hph') {
            return (
                <div>
                    <H5>Home Phone Number</H5>
                    <p>is not in a valid format.</p>
                    <p>use format: 555-876-5409</p>
                </div>);
        }
        if (popupType === 'mph') {
            return (
                <div>
                    <H5>Mobile Phone Number</H5>
                    <p>is not in a valid format.</p>
                    <p>use format: 555-876-5409</p>
                </div>);
        }
        if (popupType === 'valid') {
            return (
                <div>
                    <H5>Credit card number is not valid</H5>
                    <p>Please enter a valid credit card number and try again.</p>
                </div>);
        }
    }

    function handleTabChange(e) {

        setClickPass(false);

        updateState("paymentMethod", e);
    }

    function makeInvalid() {
        if (isValid)
            setIsValid(false);


    }

    function isNowInvalid() {
        makeInvalid();

        return {
            intent: Intent.DANGER,
            labelInfo: "(Required)"
        };
    }

    function GroupProps(name) {

        if (shouldValidate) {


            if (name === 'expiry' && hasCredit() && !isValidExpiry()) {
                makeInvalid();
                return {
                    intent: Intent.DANGER,
                    labelInfo: "(Expired)"
                }
            }

            if ((name === "name" || name === "expiry" || name === "cvc" || name === "number") && !hasCredit())
                return;


            if ((name === "achAccountNumber" || name === "achRoutingNumber" || name === "achVerfyAccountNumber") && hasCredit() && !hasACH())
                return;

            if (
                (name === 'billingstateselect' && !state.billingstate)
                || (name === 'mailingstateselect' && !state.mailingstate)
                || (name === 'dlissueselect' && !state.dlissue)
                || (name === 'lpstateselect' && !state.lpstate)) {
                makeInvalid();
                return {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#db3737"
                }
            }
            if ((name === 'billingstate' && !state.billingstate)
                || (name === 'mailingstate' && !state.mailingstate)
                || (name === 'dlissue' && !state.dlissue)
                || (name === 'lpstate' && !state.lpstate))
                return "(Required)";

            if (name === 'mailingstate'
                || name === 'billingstate'
                || name === 'dlissue'
                || name === 'lpstate'
                || name === 'companystate'
                || name === 'temporarystate')
                return '';


            if (hasACH()) {
                if (name === 'achVerfyAccountNumber' && state["achVerfyAccountNumber"] !== state["achAccountNumber"]) {
                    makeInvalid();
                    return {
                        intent: Intent.DANGER,
                        labelInfo: "(Does not match accout number)"
                    }
                }
            }

            if (paymentNotReq[state["paymentMethod"]].includes(name))
                return {};


            if (name === "mobilePhone") {
                if (!phoneValueIsValid(state[name])) {
                    return isNowInvalid();
                }
            }

            if (!notRequired.includes(name) && state[name] === '') {

                return isNowInvalid();
            }

           


            if (name === "backupEmail" || name === "email") {
                if (state[name] !== '') {
                    if (!isValidEmail(state[name])) {
                        makeInvalid();

                        return {
                            intent: Intent.DANGER,
                            labelInfo: "(Not a valid email address)"
                        };
                    }
                }
            }

            const badCharLength = (l) => {
                makeInvalid();
                return {
                    intent: Intent.DANGER,
                    labelInfo: `(Max ${l})`
                }
            }

            if (addressFields.includes(name) && state[name] && state[name].length > 50)
                return badCharLength('50');

            if (name === 'companyName' && state.companyName && state.companyName.length > 100)
                return badCharLength('100');

            if (name === 'companyName' && state.companyName && state.companyName.length > 100)
                return badCharLength('100');

            if (name === 'email' && state.email && state.email.length > 255)
                return badCharLength('255');

            if (name === 'backupEmail' && state.backupEmail && state.backupEmail.length > 255)
                return badCharLength('255');

            if (name === 'homePhone' && state.homePhone && state.homePhone.length > 20)
                return badCharLength('20');

            if (name === 'mobilePhone' && state.mobilePhone && state.mobilePhone.length > 20)
                return badCharLength('20');

            if (name === 'achRoutingNumber' && state.achRoutingNumber && state.achRoutingNumber.length > 9)
                return badCharLength('9');

            if (name === 'shippingMessage' && state.shippingMessage && state.shippingMessage.length > 500)
                return badCharLength('500');

            if (name === 'suffix' && state.suffix && state.suffix.length > 5)
                return badCharLength('5');

            if (name === 'lastName' && state.lastName && state.lastName.length > 50)
                return badCharLength('50');

            if (name === 'firstName' && state.firstName && state.firstName.length > 50)
                return badCharLength('50');

            if (name === 'lpnum' && state.lpnum && state.lpnum.length > 25)
                return badCharLength('25');

            if (name === 'achAccountNumber' && state.achAccountNumber && state.achAccountNumber.length > 35)
                return badCharLength('35');

            if (name === 'achRoutingNumber' && state.achRoutingNumber && state.achRoutingNumber.length > 9)
                return badCharLength('9');

            if (name === 'dlnum' && state.dlnum && state.dlnum.length > 50)
                return badCharLength('50');

            if (name === 'MiddleInitial' && state.middleinitial && state.middleinitial.length > 1)
                return badCharLength('1');
        }

        if (name === 'mailingstate' || name === 'billingstate' || name === 'dlissue' || name === 'lpstate' || name === 'temporarystate' || name === 'companystate')
            return '';


        

        return {};
    }
    function isValidExpiry() {
        const value = state.expiry.replace(/\D+/g, "");
        const month = parseInt(value.slice(0, 2));
        let year = parseInt(value.slice(2, 6));
        if (year < 1000)
            year += 2000;
        const currentYear = new Date().getFullYear();
        if (year > currentYear)
            return true;
        if (year < currentYear)
            return false;
        const currentMonth = new Date().getMonth();
        if (month < currentMonth)
            return false;

        return true;
    }
    function isValidEmail(value) {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(value))
            return false;

        return true;

    }
    const outerSplitStyle = {
        display: "inline-block",
        width: "100%"
    };
    const innerSplitElementStyle = {
        width: "50%",
        display: "inline-block",
        paddingRight: "10px"
    };
    const innerSplit4ElementStyle = {
        width: "33%",
        display: "inline-block",
        paddingRight: "10px"
    };
    const innerSplitMiddleElementStyle = {
        width: "17%",
        display: "inline-block",
        paddingRight: "10px"
    };
    const tanksizeSplit = {
        width: "25%",
        display: "inline-block",
        paddingRight: "10px"
    }
    function AdditionalInfo() {

        return (
            <div>
                <span className="denote-requird">* = required.</span>
                <Card style={isMobile ? {} : { height: "447px" }} elevation={Elevation.TWO} >
                    <H5 className="cardHeadline" style={{ marginBottom: "0px", background: "#3D5366", color: "white", height: "25px", paddingLeft: "5px", paddingTop: "2px" }}>Additional Information</H5>
                    <form>
                        <div style={isMobile ? {} : outerSplitStyle}>
                            <div style={isMobile ? {} : innerSplitElementStyle}>
                                <FormGroup
                                    label="# of Cards*"
                                    helperText="(2 cards are included. Additional cards incur a cost of $5 each.)"
                                    {...GroupProps("numAddReqCards")}>
                                    <InputGroup
                                        small
                                        type="number"
                                        min={0}
                                        value={state.numAddReqCards}
                                        {...GroupProps("numAddReqCards")}
                                        name={"numAddReqCards"}
                                        placeholder="# of Cards requested "
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                            <div style={isMobile ? {} : tanksizeSplit}>
                                <FormGroup
                                    {...GroupProps("tankSize")}
                                    label="Diesel*">
                                    <InputGroup
                                        small
                                        type="number"
                                        min="0"
                                        {...GroupProps("tankSize")}
                                        value={state.tankSize}
                                        name={"tankSize"}
                                        placeholder="Diesel Tank Size"
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                            <div style={isMobile ? {} : tanksizeSplit}>
                                <FormGroup
                                    {...GroupProps("gasTankSize")}
                                    label="Gasoline">
                                    <InputGroup
                                        small
                                        type="number"
                                        min="0"
                                        {...GroupProps("gasTankSize")}
                                        value={state.gastankSize}
                                        name={"gastanksize"}
                                        placeholder="Gasoline Tank Size"
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                            <div style={isMobile ? {} : {
                                display: "flex",
                                width: "100%"
                            }}>
                                <div style={isMobile ? {} : innerSplitElementStyle}>
                                    <FormGroup
                                        {...GroupProps("dlnum")}
                                        label="Driver License Number*">
                                        <InputGroup
                                            maxLength="50"
                                            {...GroupProps("dlnum")}
                                            small
                                            value={state.dlnum}
                                            name={"dlnum"}
                                            placeholder="Driver License Number"
                                            onChange={handleInputChange} />
                                    </FormGroup>
                                </div>
                                <div style={isMobile ? {} : innerSplitElementStyle}>
                                    <LabelStateSelect
                                        labelText={"State of Issue*"}
                                        labelInfo={<span className="bp3-text-muted">{GroupProps("dlissue")}</span>}
                                        selectedState={state.dlissue}
                                        selectProps={
                                            {

                                                style: {
                                                    ...GroupProps("dlissueselect"),
                                                    height: "24px"
                                                },
                                                small: true,
                                                onChange: (e) => {
                                                    handleInputChange({
                                                        target: {
                                                            name: "dlissue", value: e.target.value
                                                        }
                                                    });
                                                }
                                            }} />

                                </div>
                            </div>

                            <div style={isMobile ? {} : {
                                display: "flex",
                                width: "100%"
                            }}>
                                <div style={isMobile ? {} : innerSplitElementStyle}>
                                    <FormGroup
                                        {...GroupProps("lpnum")}
                                        label="License Plate #*">
                                        <InputGroup
                                            small
                                            maxLength="25"
                                            {...GroupProps("lpnum")}
                                            value={state.lpnum}
                                            name={"lpnum"}
                                            placeholder="License Plate #"
                                            onChange={handleInputChange} />
                                    </FormGroup>
                                </div>
                                <div style={isMobile ? {} : innerSplitElementStyle}>

                                    <LabelStateSelect
                                        labelText={"Plate State of Issue* "}
                                        labelInfo={<span className="bp3-text-muted">{GroupProps("lpstate")}</span>}
                                        selectedState={state.lpstate}
                                        selectProps={
                                            {
                                                style: {
                                                    ...GroupProps("lpstateselect"),
                                                    height: "24px"
                                                },
                                                small: true,
                                                onChange: (e) => {
                                                    handleInputChange({
                                                        target: {
                                                            name: "lpstate", value: e.target.value
                                                        }
                                                    });
                                                }
                                            }} />

                                </div>
                            </div>

                            {!state.isMds && <FormGroup
                                style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                {...GroupProps("referrer")}
                                label="Membership Type*"
                                helperText="Membership automatically renews Monthy / Yearly"
                            >

                                <HTMLSelect
                                    {...GroupProps("referrerSelect")}
                                    name="custType"
                                    small
                                    fill
                                    onChange={(e) => updateState("custType", e.target.value)}>
                                    <option value={1} selected>$40 charged yearly</option>
                                    <option value={2}>$4 charged monthly</option>
                                </HTMLSelect>
                            </FormGroup>}

                        </div>

                    </form>
                </Card>
            </div >);
    }

    function ContactInfo() {

        return (
            <>
                <Card elevation={Elevation.TWO}
                    style={!isMobile ? { height: "447px" } : {}}
                >
                    <H5 className="cardHeadline" style={{ marginBottom: "0px", background: "#3D5366", color: "white", height: "25px", paddingLeft: "5px", paddingTop: "2px" }}>Contact Information</H5>
                    <form>
                        <div style={isMobile ? {} : outerSplitStyle}>
                            <div style={isMobile ? {} : innerSplit4ElementStyle}>
                                <FormGroup {...GroupProps("firstName")} label={"First Name *"}  >
                                    <InputGroup
                                        maxLength="50"
                                        small
                                        {...GroupProps("firstName")}
                                        value={state.firstName}
                                        name={"firstName"}
                                        placeholder="First Name"
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                            <div style={isMobile ? {} : innerSplitMiddleElementStyle}>
                                <FormGroup {...GroupProps("MiddleInitial")} label="MI">
                                    <InputGroup
                                        small
                                        maxLength="1"
                                        {...GroupProps("MiddleInitial")}
                                        name={"middleinitial"}
                                        placeholder="Middle"
                                        value={state.middleinitial}
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                            <div style={isMobile ? {} : innerSplit4ElementStyle}>
                                <FormGroup {...GroupProps("lastName")} label="Last Name*">
                                    <InputGroup
                                        small
                                        maxLength="50"
                                        {...GroupProps("lastName")}
                                        name={"lastName"}
                                        placeholder="Last Name"
                                        value={state.lastName}
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                            <div style={isMobile ? {} : innerSplitMiddleElementStyle}>
                                <FormGroup {...GroupProps("suffix")} label="Suffix">
                                    <InputGroup
                                        small
                                        maxLength="5"
                                        {...GroupProps("suffix")}
                                        name={"suffix"}
                                        placeholder="Jr, III, IV"
                                        value={state.suffix}
                                        onChange={handleInputChange} />
                                </FormGroup>
                            </div>
                        </div>
                        <FormGroup {...GroupProps("mobilePhone")} label="Primary Phone*">
                            <InputGroup
                                small
                                maxLength="20"
                                {...GroupProps("mobilePhone")}
                                name={"mobilePhone"}
                                type="tel"
                                placeholder="eg: 555-876-5409"
                                value={state.mobilePhone}
                                onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup {...GroupProps("homePhone")} label="Alternate Phone">
                            <InputGroup
                                small
                                maxLength="20"
                                {...GroupProps("homePhone")}
                                type="tel"
                                name={"homePhone"}
                                placeholder="eg: 201-867-5309"
                                value={state.homePhone}
                                onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup label="E-mail*" {...GroupProps("email")}>
                            <InputGroup
                                small
                                maxLength="255"
                                type="email"
                                {...GroupProps("email")}
                                name={"email"}
                                placeholder="E-mail"
                                value={state.email}
                                onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup label="Backup E-mail" {...GroupProps("backupEmail")}>
                            <InputGroup
                                small
                                maxLength="255"
                                type="email"
                                {...GroupProps("backupEmail")}
                                name={"backupEmail"}
                                placeholder="E-mail"
                                value={state.backupEmail}
                                onChange={handleInputChange} />
                        </FormGroup>

                    </form>
                </Card>
            </>
        );
    }

    function postAddressValidation(entered, title, type) {

        axios({
            url: 'api/customerportal/addressValidation',
            method: 'post',
            timeout: 60000,
            data: entered
        }).then((r) => {

            if (r.data.isCorrected || r.data.addressNotFound) {
                setAddressToValidate({
                    title: title,
                    type: type,
                    address: { ...entered },
                    response: { ...r.data }
                });
                setShowProcessing(5);
            }
            else
                setValidationAndGo(type);

        }).catch((e) => {
            console.log(e);
            setValidationAndGo(type);
        });
    }

    function setValidationAndGo(type) {
        const validations = { ...addressValidated };

        if (type === 'mailing')
            validations.mailing = true;
        if (type === 'billing' || (type === 'mailing' && state.sameAs))
            validations.billing = true;
        if (type === 'company')
            validations.company = true;
        if (type === 'temporary')
            validations.temporary = true;

        setAddressValidated(validations);
        setShowProcessing(4);

    }

    function performValidations(validations) {
        setShowProcessing(7);

        if (!validations.mailing) {

            const mailing = {
                address1: state.mailingaddress,
                address2: state.mailingaddress2,
                city: state.mailingcity,
                state: state.mailingstate,
                zip: state.mailingzip
            };
            postAddressValidation(mailing, "Mailing Address", 'mailing')
            return;
        }

        if (!validations.billing) {

            const billing = {
                address1: state.billingaddress,
                address2: state.billingaddress2,
                city: state.billingcity,
                state: state.billingstate,
                zip: state.billingzip
            };

            postAddressValidation(billing, "Billing Address", 'billing')
            return;
        }

        if (!validations.company) {
            const company = {
                address1: state.companyaddress,
                address2: state.companyaddress2,
                city: state.companycity,
                state: state.companystate,
                zip: state.companyzip
            }
            if (company.address1 && company.city && company.state && company.zip)
                postAddressValidation(company, "Company Address", 'company')
            else
                setValidationAndGo('company');
            return;
        }

        if (!validations.temporary) {
            const temporary = {
                address1: state.temporaryaddress,
                address2: state.temporaryaddress2,
                city: state.temporarycity,
                state: state.temporarystate,
                zip: state.temporaryzip
            }

            if (temporary.address1 && temporary.city && temporary.state && temporary.zip)
                postAddressValidation(temporary, "Temporary Address", 'temporary')
            else
                setValidationAndGo('temporary');

            return;
        }

        setShowProcessing(8);
        //post();
    }

    function ChooseButton(onChoose) {
        return (
            <Button
                intent={Intent.WARNING}
                icon="floppy-disk"
                disabled={state.saving}
                loading={state.saving}
                onClick={onChoose}>
                Choose
            </Button>);
    }

    function choosebuttonClick(which, type) {
        if (which === 'e')
            setValidationAndGo(type);

        if (which === 'c') {
            let newState = { ...state }
            if (type === 'mailing') {
                newState.mailingaddress = addressToValidate.response.address1;
                newState.mailingaddress2 = addressToValidate.response.address2;
                newState.mailingcity = addressToValidate.response.city;
                newState.mailingstate = addressToValidate.response.state;
                newState.mailingzip = addressToValidate.response.zip;
                if (state.sameAs) {
                    newState.billingaddress = addressToValidate.response.address1;
                    newState.billingaddress2 = addressToValidate.response.address2;
                    newState.billingcity = addressToValidate.response.city;
                    newState.billingstate = addressToValidate.response.state;
                    newState.billingzip = addressToValidate.response.zip;
                }
            }

            if (type === 'billing') {
                newState.billingaddress = addressToValidate.response.address1;
                newState.billingaddress2 = addressToValidate.response.address2;
                newState.billingcity = addressToValidate.response.city;
                newState.billingstate = addressToValidate.response.state;
                newState.billingzip = addressToValidate.response.zip;
            }

            if (type === 'company') {
                newState.companyaddress = addressToValidate.response.address1;
                newState.companyaddress2 = addressToValidate.response.address2;
                newState.companycity = addressToValidate.response.city;
                newState.companystate = addressToValidate.response.state;
                newState.companyzip = addressToValidate.response.zip;
            }

            if (type === 'temporary') {
                newState.temporaryaddress = addressToValidate.response.address1;
                newState.temporaryaddress2 = addressToValidate.response.address2;
                newState.temporarycity = addressToValidate.response.city;
                newState.temporarystate = addressToValidate.response.state;
                newState.temporaryzip = addressToValidate.response.zip;
            }

            setState(newState);
            setValidationAndGo(type);
        }
    }

    function cancelAddressValidation() {
        setShowProcessing(0);
    }

    function showAddressValidationPanel() {
        return (
            <div style={{ width: isMobile ? "100%" : "770px", margin: "15% auto" }}>
                <img src={titleImg} alt="Join My Diesel Savings" width={isMobile ? "250px" : "770px"} />
                <H2>USPS found a different address</H2>
                <H5>Select the best address to use or cancel to return</H5>
                <div style={{ height: "15px" }} />
                <div>
                    <Card elevation={Elevation.TWO}>
                        <H5>Entered for {addressToValidate.title}</H5>
                        <p><span>Address: </span><span><strong>{addressToValidate.address.address1}</strong></span></p>
                        <p><span>Address2: </span><span><strong>{addressToValidate.address.address2}</strong></span></p>
                        <p><span>City: </span><span><strong>{addressToValidate.address.city}</strong></span></p>
                        <p><span>State: </span><span><strong>{addressToValidate.address.state}</strong></span></p>
                        <p><span>Zip: </span><span><strong>{addressToValidate.address.zip}</strong></span></p>
                        {ChooseButton(() => choosebuttonClick('e', addressToValidate.type))}
                    </Card>

                    <div style={{ height: "15px" }} />
                    {CorrectedCard()}
                    <div style={{ height: "15px" }} />

                    <div style={{ float: "right" }}>
                        <Button
                            intent={Intent.PRIMARY}
                            icon="undo"

                            onClick={cancelAddressValidation}>
                            Cancel
                            </Button>
                    </div>
                </div>
            </div>
        );
    }

    function CorrectedCard() {
        if (addressToValidate.response.addressNotFound)
            return (<Card elevation={Elevation.TWO}>
                <H5>Corrected Address</H5>
                <p><strong>Address Not Found</strong></p>
                <p>Cancel to correct the address.</p>
            </Card>);

        return (<Card elevation={Elevation.TWO} >
            <H5>Corrected Address</H5>
            <p><span>Address: </span><span><strong>{addressToValidate.response.address1}</strong></span></p>
            <p><span>Address2: </span><span><strong>{addressToValidate.response.address2}</strong></span></p>
            <p><span>City: </span><span><strong>{addressToValidate.response.city}</strong></span></p>
            <p><span>State: </span><span><strong>{addressToValidate.response.state}</strong></span></p>
            <p><span>Zip: </span><span><strong>{addressToValidate.response.zip}</strong></span></p>
            {ChooseButton(() => choosebuttonClick('c', addressToValidate.type))}
        </Card>);
    }

    function addressValidation() {
        return (
            <div style={{ width: isMobile ? "100%" : "770px", margin: "15% auto" }}>
                <img src={titleImg} alt="Join My Diesel Savings" width={isMobile ? "250px" : "770px"} />
                <Callout icon={"exchange"} intent={Intent.NONE} style={{ marginTop: "15px" }} title={"Validating Addresses..."}>
                    One minute while we validate your addresses with the USPS servers.
                    <ProgressBar animate />
                </Callout>

            </div>
        );
    }

    function post(vers) {

        setShowProcessing(1);
        const headers = {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            'Access-Control-Allow-Origin': '*',
        };

        const params =
            { ...state };

        params["pdfVersions"] = vers;

        setTimeout(() => {

            axios.post('api/customerportal/escapeesjoin',
                params, {
                headers: headers
            }).then((response) => {
                handleJoinResponse(response);
            }).catch((e) => handleJoinError(e));
        }, 1000);
    }

    function handleJoinResponse(response) {
        setShowProcessing(2);

    }

    function handleJoinError(e) {
        setShowProcessing(3);
        console.log("an error Occurred while posting data to the server.");
        console.log(e);

    }

    const addressStyle = {
        width: "50%",
        marginTop: "10px",
        display: "inline-block"
    }
    const mailingAddressStyle = {
        width: "50%",
        marginTop: "40px",
        display: "inline-block"
    }
    const mobileAddressStyle = {
        marginTop: "10px"
    }
    const mobileRightAddressStyle = {
        marginTop: "10px"
    }
    const addressContainer = {
        display: "inline-block",
        width: "100%"
    }
    const rightAddressShort = {
        width: "50%",
        marginTop: "10px",
        display: "inline-block",
        paddingLeft: "10px",
        float: "right"
    }
    const achTabStyle =
    {
        color: "white",
        marginRight: isMobile ? "15px" : ""
    }


    function signup() {

        return (
            <div style={{ width: "100%", paddingTop: "5px" }}>

                <img src={titleImg} alt="Join My Diesel Savings" width={isMobile ? "250px" : "50%"} />
                <div style={isMobile ? {} : addressContainer}>
                    <div style={isMobile ? mobileAddressStyle : addressStyle}>
                        {ContactInfo()}
                    </div>
                    <div style={isMobile ? mobileRightAddressStyle : rightAddressShort}>
                        {AdditionalInfo()}
                    </div>
                </div>

                <div style={isMobile ? {} : addressContainer}>
                    <div style={isMobile ? mobileAddressStyle : mailingAddressStyle}>
                        <Address
                            bgc={"#3D5366"}
                            GroupProps={GroupProps}
                            prefix="mailing"
                            handleInputChange={handleInputChange}
                            title={"Mailing Address*"}
                            values={state} />
                    </div>
                    <div style={isMobile ? mobileRightAddressStyle : rightAddressShort}>
                        <Checkbox
                            onChange={handleSameAs}
                            checked={state.sameAs}
                            label="Same As Mailing Address" />
                        <Address
                            bgc={"#3D5366"}
                            GroupProps={GroupProps}
                            prefix={state.sameAs ? "mailing" : "billing"}
                            disabled={state.sameAs}
                            handleInputChange={handleInputChange}
                            title={"Billing Address*"}
                            values={state} />
                    </div>
                </div>

                <div style={isMobile ? {} : addressContainer}>
                    <div style={isMobile ? mobileAddressStyle : addressStyle}>
                        <Card elevation={Elevation.TWO} style={!isMobile ? { height: "400px" } : {}} >
                            <H5 className="paymentHeadline" style={{ marginBottom: "0px", background: "#3D5366", color: "white", height: "25px", paddingLeft: "5px", paddingTop: "2px" }}>Shipping Options</H5>
                            <div style={{
                                width: "75%",
                                margin: "25px auto"
                            }}>

                                <RadioGroup
                                    onChange={handleShippingOptionsRadioChange}
                                    selectedValue={state.shippingOption}>
                                    {shippingOptions && shippingOptions.map(opt =>
                                        <Radio label={opt.description} value={String(opt.shippingOptionsId)}>
                                            <span className="shipping-options-sub"> {opt.displayText}</span>
                                        </Radio>
                                    )}

                                </RadioGroup>

                                <Label style={{ marginTop: "25px", marginBottom: "0px" }}>Special Shipping Directions</Label>
                                <span>({500 - state.shippingMessage.length} characters remaining)</span>

                                <TextArea
                                    style={{ height: "100px", width: "100%", marginTop: "15px" }}
                                    maxLength={500}
                                    name="shippingMessage"
                                    onChange={handleInputChange}
                                    value={state.shippingMessage} />
                            </div>
                        </Card>
                    </div>
                    <div style={isMobile ? mobileRightAddressStyle : rightAddressShort}>
                        <Address
                            bgc={"#3D5366"}
                            GroupProps={(e) => { return ''; }}
                            prefix={"temporary"}
                            handleInputChange={handleInputChange}
                            title={"Shipping Address (If filled in, we will ship here!)"}
                            values={state}
                            extraSpace={isMobile ? false : true} />
                    </div>
                </div >

                <Card elevation={Elevation.TWO} style={{ marginTop: "15px", height: isMobile ? clickPass ? "450px" : state.paymentMethod === 'credit' ? "450px" : "300px" : "325px" }} >
                    <Tabs className="escapees_payment_tab" id="TabsExample"
                        style={{ background: "#3D5366", height: "35px" }}
                        onChange={handleTabChange} selectedTabId={state.paymentMethod}>

                        <H5 style={{ marginBottom: "0px", background: "#3D5366", color: "white", height: "25px", paddingLeft: "5px" }}>Payment Information*</H5>


                        <Tab id="ach" title="ACH" style={achTabStyle} panel={achPaymentMethod()} />
                        <div style={{ background: "white", height: "20px", width: "2px", marginBottom: "4px" }} />
                        <Tab id="credit" title="Credit" style={{ color: "white" }} panel={creditPaymentMethod()} />

                    </Tabs>
                </Card>

                <div style={{ float: "right", paddingTop: "15px", marginBottom: "50px" }}>
                    <Popover
                        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                        enforceFocus={false}
                        canEscapeKeyClose
                        captureDismiss
                        onClose={() => setPopupOpen(false)}
                        isOpen={popupOpen}>

                        <Button large onClick={HandleSubmit} rightIcon="arrow-right" intent="success" text="Submit" />
                        {getPopupContent()}

                    </Popover>
                </div>
            </div >
        );
    }

    function finish() {
        return (
            <div style={{ width: isMobile ? "100%" : "770px", margin: "15% auto" }}>
                <img src={titleImg} alt="Join Savings Program" width={isMobile ? "250px" : "770px"} />
                <Callout icon={"tick"} intent={Intent.SUCCESS} style={{ marginTop: "15px" }} title={"Success!!"}>
                    Thank you for signing up. Your data has been submitted for review. The team will be reaching out shortly.
                    <p>NOTE: Please whitelist escapeessupport@fuelaccessnetwork.com to ensure you get updates about your cards progress!</p>
                </Callout>

                {
                    //<Button style={{ margin: "15px auto", display: "block" }} Intent={Intent.SUCCESS} onClick={() => window.location.assign('https://www.mydieselsavings.com')}>Continue </Button>
                }
            </div>);
    }

    function processing() {
        return (
            <div style={{ width: isMobile ? "100%" : "770px", margin: "15% auto" }}>
                <img src={titleImg} alt="Join Savings Program" width={isMobile ? "250px" : "770px"} />
                <Callout icon={"exchange"} intent={Intent.NONE} style={{ marginTop: "15px" }} title={"Processing..."}>
                    One minute while we transfer your data to our secure servers.
                    <ProgressBar animate />
                </Callout>
            </div>
        );
    }

    function error() {
        return (
            <div style={{ width: isMobile ? "100%" : "770px", margin: "15% auto" }}>
                <img src={titleImg} alt="Join Savings Program" width={isMobile ? "250px" : "770px"} />
                <Callout icon={"error"} intent={Intent.DANGER} style={{ marginTop: "15px" }} title={"Something Went Wrong"}>
                    We are sorry, something went wrong. please email our support staff so we can provide a better experience in the future.
                    <a href="mailto:escapeessupport@fuelaccessnetwork.com?subject=Application%20Support%20Issue&body=Please%20describe%20your%20issue">
                        Send Feedback
                    </a>
                </Callout>
            </div>);
    }
    function badQueryStrings() {
        return (
            <div style={{ width: isMobile ? "100%" : "770px", margin: "15% auto" }}>
                <img src={titleImg} alt="Join Savings Program" width={isMobile ? "250px" : "770px"} />
                <div style={{ height: "15px" }} />
                <div>
                    <Card elevation={Elevation.TWO}>
                        <H5>Something went wrong</H5>
                        <p> There is some missing data from the request and the application can not be loaded</p>
                    </Card>
                </div>
            </div>);
    }
    function main() {
        if ((!state.isMds && (!state.partnerCustomerCode || !state.partnerExpiration)) || !state.cardFrom)
            return badQueryStrings();
        
        if (showProcessing === 1)
            return processing();

        if (showProcessing === 2)
            return finish();

        if (showProcessing === 3)
            return error();

        if (showProcessing === 4)
            performValidations(addressValidated);

        if (showProcessing === 8) {
            return showPdfs();
        }

        if (showProcessing === 7) {
            return addressValidation();
        }
        if (showProcessing === 5)
            return showAddressValidationPanel();

        if (showProcessing === 6)
            return (<>done</>);

        return signup();
    }


    return (
        <div className="escapeesJoin">
            {imageReady ? main() : <></>}
        </div>
    );
}
