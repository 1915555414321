import React from 'react';
import { HTMLSelect, Label } from '@blueprintjs/core';

export default function StateDropDown(props) {

    const states = [
        { id: "1", name: "Alabama", abrv: "AL" },
        { id: "2", name: "Alaska", abrv: "AK" },
        { id: "3", name: "Arizona", abrv: "AZ" },
        { id: "4", name: "American Samoa", abrv: "AS" },
        { id: "5", name: "Arkansas", abrv: "AR" },
        { id: "6", name: "California", abrv: "CA" },
        { id: "7", name: "Colorado", abrv: "CO" },
        { id: "8", name: "Connecticut", abrv: "CT" },
        { id: "9", name: "Delaware", abrv: "DE" },
        { id: "10", name: "District of Columbia", abrv: "DC" },
        { id: "11", name: "Federated States of Micronesia ", abrv: "FM" },
        { id: "12", name: "Florida", abrv: "FL" },
        { id: "13", name: "Georgia", abrv: "GA" },
        { id: "14", name: "Guam", abrv: "GU" },
        { id: "15", name: "Hawaii", abrv: "HI" },
        { id: "16", name: "Idaho", abrv: "ID" },
        { id: "17", name: "Illinois", abrv: "IL" },
        { id: "18", name: "Indiana", abrv: "IN" },
        { id: "19", name: "Iowa", abrv: "IA" },
        { id: "20", name: "Kansas", abrv: "KS" },
        { id: "21", name: "Kentucky", abrv: "KY" },
        { id: "22", name: "Louisiana", abrv: "LA" },
        { id: "23", name: "Maine", abrv: "ME" },
        { id: "24", name: "Marshall Islands", abrv: "MH" },
        { id: "25", name: "Maryland", abrv: "MD" },
        { id: "26", name: "Massachusetts", abrv: "MA" },
        { id: "27", name: "Michigan", abrv: "MI" },
        { id: "28", name: "Minnesota", abrv: "MN" },
        { id: "29", name: "Mississippi", abrv: "MS" },
        { id: "30", name: "Missouri", abrv: "MO" },
        { id: "31", name: "Montana", abrv: "MT" },
        { id: "32", name: "Nebraska", abrv: "NE" },
        { id: "33", name: "Nevada", abrv: "NV" },
        { id: "34", name: "New Hampshire", abrv: "NH" },
        { id: "35", name: "New Jersey", abrv: "NJ" },
        { id: "36", name: "New Mexico", abrv: "NM" },
        { id: "37", name: "New York", abrv: "NY" },
        { id: "38", name: "North Carolina", abrv: "NC" },
        { id: "39", name: "North Dakota", abrv: "ND" },
        { id: "40", name: "Northern Mariana Islands", abrv: "MP" },
        { id: "41", name: "Ohio", abrv: "OH" },
        { id: "42", name: "Oklahoma", abrv: "OK" },
        { id: "43", name: "Oregon", abrv: "OR" },
        { id: "44", name: "Palau", abrv: "PW" },
        { id: "45", name: "Pennsylvania", abrv: "PA" },
        { id: "46", name: "Puerto Rico", abrv: "PR" },
        { id: "47", name: "Rhode Island", abrv: "RI" },
        { id: "48", name: "South Carolina", abrv: "SC" },
        { id: "49", name: "South Dakota", abrv: "SD" },
        { id: "50", name: "Tennessee", abrv: "TN" },
        { id: "51", name: "Texas", abrv: "TX" },
        { id: "52", name: "Utah", abrv: "UT" },
        { id: "53", name: "Vermont", abrv: "VT" },
        { id: "54", name: "Virgin Islands", abrv: "VI" },
        { id: "55", name: "Virginia", abrv: "VA" },
        { id: "56", name: "Washington", abrv: "WA" },
        { id: "57", name: "West Virginia", abrv: "WV" },
        { id: "58", name: "Wisconsin", abrv: "WI" },
        { id: "59", name: "Wyoming", abrv: "WY" }];

    let selected = 'Choose...';

    if (props.selectedState) {
        selected = '';
        var idx = states.findIndex(f => f.name.toLowerCase() === props.selectedState.toLowerCase() || f.abrv === props.selectedState.toUpperCase())
        if (idx !== -1)
            selected = states[idx].abrv;
    }

    function value(s) {
        if (s === '' || s === 'Choose...')
            return;

        return { value: s };
    }
    function options(s) {
        if (s === 'Choose...')
            return (<option selected>Choose...</option>);
        else if (s === '')
            return (<option selected>{props.selectedState}</option>);
        else
            return (<></>);
    }

    if (props.noLabel)
        return (
            <HTMLSelect
                {...props.selectProps}
                {...value(selected)}
                options={states.map((l) => { return { label: l.name, value: l.abrv } })}>
                {options(selected)}
            </HTMLSelect>
        );

    return (
        <Label {...props.labelProps}>
            {props.labelText}
            {props.labelInfo}
            <HTMLSelect
                {...props.selectProps}
                {...value(selected)}
                options={states.map((l) => { return { label: l.name, value: l.abrv } })}>
                {options(selected)}
            </HTMLSelect>
        </Label>
    );
}