import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import Payment from "payment";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { isMobile } from 'react-device-detect';
export class CreditCardPaymentInfo extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
        };
    }
    componentDidMount() {
        this.setState({
            cvc: this.props.cvc,
            expiry: this.props.expiry,
            focus: '',
            name: this.props.name,
            number: this.props.number,
        });
    }

    clearNumber(value = "") {
        return value.replace(/\D+/g, "");
    }

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleCallback = (cardtype, isValid) => {
        
        if (cardtype !== null) {
            this.props.handleCardValidAndIssuer(cardtype.issuer, isValid);
        }
    }

    handleInputChange = ({ target }) => {
        if (target.name === "number") {
            target.value = this.formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = this.formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = this.formatCVC(target.value);
        }

        if (target.value == null)
            target.value = '';

        this.setState({ [target.name]: target.value });
        this.props.handleInputChange(target.name, target.value);
    };

    formatCreditCardNumber(value) {
        if (!value) {
            return value;
        }

        const issuer = Payment.fns.cardType(value);
        const clearValue = this.clearNumber(value);
        let nextValue;

        switch (issuer) {
            case "amex":
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10
                )} ${clearValue.slice(10, 15)}`;
                break;
            case "dinersclub":
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10
                )} ${clearValue.slice(10, 14)}`;
                break;
            default:
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    8
                )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
                break;
        }

        return nextValue.trim();
    }

    formatCVC(value, prevValue, allValues = {}) {
        const clearValue = this.clearNumber(value);
        let maxLength = 4;

        if (allValues.number) {
            const issuer = Payment.fns.cardType(allValues.number);
            maxLength = issuer === "amex" ? 4 : 3;
        }

        return clearValue.slice(0, maxLength);
    }

    formatExpirationDate(value) {
        const clearValue = this.clearNumber(value);

        if (clearValue.length >= 3) {
            return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
        }

        return clearValue;
    }

    formatFormData(data) {
        return Object.keys(data).map(d => `${d}: ${data[d]}`);
    }

    
    render() {
        const paymentCardStyle = {
            flex: "225px"
        };

        const paymentFormStyle = { display: "flex" };

        const outerFormGroupStyle = {
            flex: "100%"
        };
        const innerFormGroupStyle = {
            maxWidth: "400px",
            paddingLeft: "25px"
        };

        return (
            <div id="PaymentForm" style={isMobile ?  {} : paymentFormStyle}>
                <div style={isMobile ? {} : paymentCardStyle}>
                    <Cards
                        callback={this.handleCallback}
                        acceptedCards={["visa", "mastercard", "discover"]}
                        cvc={this.state.cvc}
                        expiry={this.state.expiry}
                        focused={this.state.focus}
                        name={this.state.name}
                        number={this.state.number} />
                </div>


                <div style={isMobile ? {} : outerFormGroupStyle} >
                    <div style={isMobile ? {} : innerFormGroupStyle}>
                        <FormGroup
                            label="Card Number"
                            {...this.props.GroupProps("number")}>
                            <InputGroup
                                small
                                value={this.state.number}
                                maxLength="25"
                                {...this.props.GroupProps("number")}
                                type={"tel"}
                                pattern={"[\d| ]{16,22}"}
                                name="number"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                id="card-number-input"
                                placeholder="Card Number" />
                        </FormGroup>
                        <FormGroup
                            label="Name"
                            {...this.props.GroupProps("name")}>
                            <InputGroup
                                small
                                maxLength="100"
                                value={this.state.name}
                                type={"text"}
                                {...this.props.GroupProps("name")}
                                name="name"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                id="card-name-input"
                                placeholder="Name" />
                        </FormGroup>

                        <div style={{ display: "flex" }}>
                            <div style={{ flex: "50%" }}>
                                <FormGroup
                                    label="Valid Thru"
                                    {...this.props.GroupProps("expiry")}>
                                    <InputGroup
                                        small
                                        maxLength="10"
                                        value={this.state.expiry}
                                        {...this.props.GroupProps("expiry")}
                                        type={"tel"}
                                        name="expiry"
                                        pattern={"(0[1-9]|10|11|12)/20[0-9]{2}$"}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        id="card-expiry-input"
                                        placeholder="Valid Thru" />
                                </FormGroup>
                            </div>
                            <div style={{ flex: "50%", paddingLeft: "5px" }}>
                                <FormGroup
                                    label="CVC"
                                    {...this.props.GroupProps("cvc")}>
                                    <InputGroup
                                        small
                                        type={"tel"}
                                        name="cvc"
                                        value={this.state.cvc}
                                        {...this.props.GroupProps("cvc")}
                                        pattern={"\d{3,4}"}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        id="card-cvc-input"
                                        placeholder="CVC" />
                                </FormGroup>
                            </div>
                            <div style={{
                                position: "absolute", left: "70px", paddingTop: "70px" }}>
                                <img style={{ width: "200px" }} src="visa-mastercard-discover-logo.png" alt="we accept visa, mc and discover" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}