import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Join from './components/Join';
import EscapeesJoin from './components/EscapeesJoin';
import './custom.css'

export default function App(props) {
    document.title = "Welcome";
    const AccountManagement = React.lazy(() => import('./components/AccountManagement/AccountManagement'));
    const Map = React.lazy(() => import('./components/StationsMap/maps'));
    const ExtendedMap = React.lazy(() => import('./components/StationsMap/extendedMaps'))
    return (
        <Layout>
            <Route exact path='/' render={(props) => <Join {...props} />} />
            <React.Suspense fallback={<></>}>
                <Route path='/accountmanagement' render={(props) => <AccountManagement {...props} />} />
                <Route path='/map' render={(props) => <Map {...props} />} />
                <Route path='/extendedmap' render={(props) => <ExtendedMap {...props} />}/>
            </React.Suspense>
            <Route path='/escapeesjoin' component={EscapeesJoin} />
        </Layout>
    );

}
